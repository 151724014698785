.carousel {
  position: relative;
  width: 100%;
  max-width: 1400px;
  height: 600px;
  margin: 0 auto;
}

@media (min-width: 1920px) {
  .carousel {
    max-width: 1800px;
  }
}

.carousel-inner {
  height: 100%;
  margin-top: 9%;
  margin: auto;
}

.carousel-item {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.carousel-control-prev,
.carousel-control-next {
  width: 7% !important;
  height: 110%;
}


.carousel-control-prev:hover::after,
.carousel-control-next:hover::after {
  opacity: 1;
}

.carousel-container {
  padding: 0 25px;
}

.carousel-image {
  height: 600px;
  background-size: cover;
  background-position: center;
}