.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-content {
  text-align: center;
  padding: 20px;
}

.error-icon {
  font-size: 100px;
}

.error-message {
  font-size: 24px;
}