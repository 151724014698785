.navbar-toggler {
  border: none;
  background: none;
}

.sidebar {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  background-color: #333;
  color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  z-index: 1050;
  padding: 1rem;
}

.sidebar-open {
  transform: translateX(0);
}

.sidebar-close {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.sidebar nav a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 0;
  border-bottom: 1px solid #444;
}

.sidebar nav a:hover {
  background-color: #555;
}