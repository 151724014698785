.instagram{
  color: #e01465 !important;
}
.facebook{
  color: #147de0 !important;
}
.whatsapp{
  color: #25D366 !important;
}
.telegram{
  color: #147de0 !important;
}
.footer-icon:hover {
  color: #70abaa !important;
}

.footer-icon {
  color: white;
  transition: color 0.3s ease;
}

.footer-icon:hover {
  color: rgba(255, 255, 255, 0.5);
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  color: white;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.whatsapp-icon:hover {
  background-color: #128C7E;
}