.custom-modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 1rem);
}

.custom-modal-content {
  width: 90vw;
  max-width: 100%;
  margin: auto;
  background-color: #f6efef;
  box-sizing: border-box;
}


.modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #dee2e6;
}

@media (max-width: 768px) {
  .custom-modal-content {
    width: 95vw;
  }
}

.card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: normal;
  margin-bottom: 0;
}

.expanded-description {
  display: block;
  overflow-y: auto;
  max-height: 150px;
  margin-bottom: 0;
}

.btn {
  margin-top: 10px;
}