.loading-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: black;
  border-radius: 50%;
  margin: 0 5px;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}